import { FormInput, PrimaryButton, InputProps, Modal, toast } from '@systemeio/ui-shared'
import { createSenderEmailAddress } from 'modules/profile/mailing-settings/api/mailing-settings-sender-emails-api'
import { MailingSettingsSenderEmailAddressErrors } from 'modules/profile/mailing-settings/types/sender-email-address-interface'
import React, { useEffect, useState } from 'react'
import { isClient } from 'shared/components/screen-height-div'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { UpdateDataFunctionType } from 'shared/hooks/use-update-data'


const defaultErrors: MailingSettingsSenderEmailAddressErrors = {
  email: '',
  common: [],
}

function CreateSenderEmailAddressModal({
  updateSenderEmailsAddressesTable,
  withoutButton,
  value,
  error,
  wrapperRef,
}: {
  updateSenderEmailsAddressesTable?: UpdateDataFunctionType
  withoutButton?: boolean
  value?: string
  error?: InputProps['error']
  wrapperRef?: React.RefObject<HTMLDivElement>
}) {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [tempState, setTempState] = useState({ email: value || '' })
  const [isFetching, setIsFetching] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)

  useEffect(() => {
    if (value) {
      setTempState({ email: value })
    }
  }, [value])

  useEffect(() => {
    const confirmButton = isClient()
      ? wrapperRef?.current?.querySelector<HTMLButtonElement>('#js-confirm-email-button')
      : null
    const onClick = () => {
      setOpened(true)
    }
    if (confirmButton) {
      confirmButton.className =
        'text-blue hover:text-blue-300 outline-none focus-visible:text-blue-300 main-transition-color contents'
      confirmButton.addEventListener('click', onClick)
      confirmButton.type = 'button'
    }
    return () => confirmButton?.removeEventListener('click', onClick)
  }, [error, wrapperRef])

  const onClose = () => {
    setOpened(false)
  }

  const handleConfirm = async () => {
    try {
      setIsFetching(true)
      setErrors(defaultErrors)
      await createSenderEmailAddress(tempState)
      if (updateSenderEmailsAddressesTable) {
        await updateSenderEmailsAddressesTable()
      }
      onClose()
      toast.success(t('dashboard.settings.mailing.sender_email_added_title'))
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        setErrors({
          email: e.errors.fields.email?.join(''),
          common: e.errors.common,
        })
      } else if (e instanceof InternalError) {
        setErrors(t('core.error.internal_error_message'))
      }
      toast.error(t('global.error'))
    }
  }

  return (
    <>
      {!withoutButton && (
        <div className="flex gap-3 items-center [&>svg]:fill-blue [&>svg]:main-transition-colors px-6 py-6">
          <div className="cursor-pointer text-blue" onClick={() => setOpened(true)}>
            {t('dashboard.settings.mailing.sender_email.create_button.title')}
          </div>
        </div>
      )}
      <Modal
        opened={opened}
        onClose={onClose}
        title={t('dashboard.settings.mailing.sender_email.create_modal.title')}
        className="max-w-2xl"
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <FormInput
            label={<div className={'flex gap-1.5 items-center'}>{t('global.email')}</div>}
            required
            value={tempState.email}
            onChange={e => setTempState({ email: e.target.value })}
            error={errors.email}
          />
          <div className="flex justify-center gap-4">
            <PrimaryButton
              isFetching={isFetching}
              width="large"
              onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                await handleConfirm()
              }}
              type="submit"
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
          {errors.common && (
            <>
              {errors.common.map((error, index) => (
                <p className="text-sm text-red px-6" key={index}>
                  {error}
                </p>
              ))}
            </>
          )}
        </form>
      </Modal>
    </>
  )
}

export default CreateSenderEmailAddressModal
