import { Tooltip, copyTextToClipboard } from '@systemeio/ui-shared'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CopyIcon from 'shared/icons/copy-icon'
import { twMerge } from 'tailwind-merge'

interface CopyToClipboardButtonProps {
  text: Parameters<typeof copyTextToClipboard>[0]
  className?: string
  onCopy?: () => void
  disabled?: boolean
}

export function CopyToClipboardButton({
  text,
  className,
  onCopy,
  disabled,
}: CopyToClipboardButtonProps) {
  const { t } = useLocoTranslation()
  return (
    <Tooltip disabled={disabled} mode="hover" label={t('global.copy')} className={className}>
      <button
        type={'button'}
        onClick={async () => {
          await copyTextToClipboard(text, t('global.copy_clipboard'))
          onCopy?.()
        }}
        disabled={disabled}
        className="outline-none group"
      >
        <CopyIcon
          className={twMerge(
            'w-[16px] h-[16px]',
            !disabled &&
              'cursor-pointer group-focus-visible:fill-blue hover:fill-blue main-transition-colors',
          )}
        />
      </button>
    </Tooltip>
  )
}
