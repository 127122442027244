import { LocaleEnum } from '@systemeio/ui-shared'
import { BadRequest } from 'shared/errors/bad-request'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { MAILING_SETTINGS_API } from '../api/mailing-settings-api'
import {
  MailingSettingsEditInterface,
  MailingSettingsErrorResponseContentInterface,
  MailingSettingsErrorsInterface,
  MailingSettingsInterface,
} from '../types/mailing-settings-interface'

export const useUpdateMailingSettings = () => {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    MailingSettingsInterface
  >({
    method: RequestMethodsEnum.put,
    showFieldsErrorToast: true,
  })
  return {
    fetcher: async (
      data: MailingSettingsEditInterface,
      errors: MailingSettingsErrorsInterface,
      setErrors: (value: React.SetStateAction<MailingSettingsErrorsInterface>) => void,
    ) => {
      try {
        return await fetcher(`${MAILING_SETTINGS_API}`, data)
      } catch (e) {
        if (e instanceof BadRequest) {
          const mailingSettingErrors =
            e.errors as unknown as MailingSettingsErrorResponseContentInterface
          const unsubscriptionLinkTextErrors = errors.unsubscriptionLinkText

          if (mailingSettingErrors.fields.unsubscriptionLinkText) {
            Object.entries(mailingSettingErrors.fields.unsubscriptionLinkText).map(
              ([locale, error]) => {
                unsubscriptionLinkTextErrors[locale as LocaleEnum] = error.join('')
              },
            )
          }
          setErrors(prev => ({
            ...prev,
            emailMessageFooter: mailingSettingErrors.fields.emailMessageFooter?.join(''),
            senderEmailAddress: mailingSettingErrors.fields.senderEmailAddress?.join(''),
            senderEmailName: mailingSettingErrors.fields.senderEmailName?.join(''),
            sendgridApiKey: mailingSettingErrors.fields.sendgridApiKey?.join(''),
            testEmailAddress: mailingSettingErrors.fields.testEmailAddress?.join(''),
            unsubscriptionLinkText: unsubscriptionLinkTextErrors,
          }))
        }
        throw e
      }
    },
  }
}
