import { LocaleEnum } from '@systemeio/ui-shared'

export const getHelpPage = (locale?: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.FRENCH:
      return 'https://aide.systeme.io/article/63-comment-definir-la-page-daccueil-de-votre-domaine'
    case LocaleEnum.DEUTSCH:
      return 'https://help-de.systeme.io/article/1704-so-definieren-sie-die-homepage-startseite-ihrer-domain'
    case LocaleEnum.SPANISH:
      return 'https://help-es.systeme.io/article/719-como-definir-la-pagina-de-inicio-de-tu-dominio'
    case LocaleEnum.ITALIAN:
      return 'https://help-it.systeme.io/article/495-come-definire-la-homepage-del-tuo-dominio'
    case LocaleEnum.PORTUGUESE:
      return 'https://help-pt.systeme.io/article/797-como-definir-a-pagina-inicial-do-seu-dominio'
    case LocaleEnum.JAPANESE:
      return 'https://help-jp.systeme.io/article/2189-how-to-define-your-domains-homepage'
    case LocaleEnum.DUTCH:
      return 'https://help-nl.systeme.io/article/1445-hoe-bepaal-je-de-homepage-van-je-domein'
    case LocaleEnum.RUSSIAN:
      return 'https://help-ru.systeme.io/article/2089-defining-domains-homepage'
    default:
      return 'https://help.systeme.io/article/143-how-to-define-a-blog-or-a-page-created-with-systeme-io-as-your-domain-root'
  }
}
