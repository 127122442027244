import { useEffect, useState } from 'react'

export const useOnceTrue = (trigger: boolean) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (!trigger) return
    setValue(true)
  }, [trigger])

  return value
}
