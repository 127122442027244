import { Checkbox } from '@systemeio/ui-shared'
import React, { Dispatch, SetStateAction } from 'react'
import { FormSelectInlineBordered } from 'shared/components/form-select/form-select-inline-bordered'
import TransLoco from 'shared/components/trans-loco'
import { TLocoType, useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { DateRangeEnum } from '../enums/date_range_enum'
import {
  ActionRemovalConditionsEnum,
  RemovalConditionsInterface,
} from '../types/removal-conditions-interface'

const getActionRemovalConditionsData = (t: TLocoType) => [
  { id: ActionRemovalConditionsEnum.delete, caption: t('global.delete').toLowerCase() },
  {
    id: ActionRemovalConditionsEnum.unsubscribe,
    caption: t('dashboard.settings.mailing.action_removal_conditions_unsubscribe').toLowerCase(),
  },
]

const getDateRangeData = Object.values(DateRangeEnum).map((id, index) => ({
  id,
  caption: `${index + 1}`,
}))

interface ContactsCleaningSettingsProps {
  removalConditions: RemovalConditionsInterface
  setTempStateRemovalConditions: Dispatch<SetStateAction<RemovalConditionsInterface[]>>
}

export const ContactsCleaningSettings = ({
  removalConditions,
  setTempStateRemovalConditions,
}: ContactsCleaningSettingsProps) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()

  return (
    <div className={'flex gap-2 items-start'}>
      <Checkbox
        value={removalConditions.active}
        className={'pt-1'}
        onChange={() =>
          setTempStateRemovalConditions(prevState =>
            prevState.map(item => {
              if (item.type === removalConditions.type) {
                return {
                  ...removalConditions,
                  active: !removalConditions.active,
                }
              }
              return item
            }),
          )
        }
      />
      <div>
        <div>
          <span>{t('dashboard.settings.mailing.contacts_cleaning_checkbox_label')}</span>
          <div className={'inline-block mx-1'}>
            <FormSelectInlineBordered
              data={getActionRemovalConditionsData(t)}
              value={removalConditions.action}
              onChange={value =>
                value &&
                setTempStateRemovalConditions(prevState =>
                  prevState.map(item => {
                    if (item.type === removalConditions.type) {
                      return {
                        ...removalConditions,
                        action: value,
                      }
                    }
                    return item
                  }),
                )
              }
              className={'px-1 py-0.5 pr-4'}
              arrowButtonClassName={'right-1 h-[26px] w-[10px]'}
              withoutCloseIcon
            />
          </div>
          <span>
            <TransLoco
              t={t}
              i18nKey={'dashboard.settings.mailing.contacts_cleaning_text'}
              components={{ b: <b /> }}
            />
          </span>
          <div className={'inline-block mx-1'}>
            <FormSelectInlineBordered
              data={getDateRangeData}
              value={removalConditions.dateRange}
              onChange={value =>
                value &&
                setTempStateRemovalConditions(prevState =>
                  prevState.map(item => {
                    if (item.type === removalConditions.type) {
                      return {
                        ...removalConditions,
                        dateRange: value,
                      }
                    }
                    return item
                  }),
                )
              }
              withoutCloseIcon
              className={'px-1 py-0.5 pr-4'}
              arrowButtonClassName={'right-1 h-[26px] w-[10px]'}
            />
          </div>
          <span>
            <TransLoco
              t={t}
              i18nKey={
                removalConditions.type === 'email'
                  ? 'dashboard.settings.mailing.contacts_cleaning_never_already'
                  : 'dashboard.settings.mailing.contacts_cleaning_never_purchased'
              }
              components={{ b: <b /> }}
            />
          </span>
        </div>
        <div>
          (
          {removalConditions.lastCount !== 0 && removalConditions.lastExecutionDate ? (
            <TransLoco
              t={t}
              i18nKey={'dashboard.settings.mailing.contacts_cleaning_action_performed'}
              components={{ b: <b /> }}
              values={{
                count: removalConditions.lastCount,
                action:
                  removalConditions.action === ActionRemovalConditionsEnum.delete
                    ? t('dashboard.settings.mailing.contacts_cleaning_action_deleted')
                    : t('dashboard.settings.mailing.contacts_cleaning_action_unsubscribed'),
                date: dateTimeFormat(removalConditions.lastExecutionDate, {
                  timeFormat: user?.timeFormat,
                  dateFormat: user?.dateFormat,
                  timeZone: user?.timezone,
                }),
              }}
            />
          ) : (
            <TransLoco
              t={t}
              i18nKey={'dashboard.settings.mailing.contacts_cleaning_action_no_performed'}
              components={{ b: <b /> }}
            />
          )}
          )
        </div>
      </div>
    </div>
  )
}
